import React, { useEffect, useState } from "react";

//image
import Male from "../../assets/images/male.png";
//jquery
import $ from "jquery";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAgencyProfile, getAgencyWiseHost } from "../../store/agency/action";
import Pagination from "../Pagination";

const AgencyWiseUser = () => {
  const dispatch = useDispatch();
  const { agencyWiseHost, totalAgencyWiseHost } = useSelector(
    (state) => state.agency
  );

  const agency = localStorage.getItem("AGENCY");
  const parsedAgency = JSON.parse(agency);

  const [data, setData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getAgencyWiseHost(parsedAgency?._id, activePage, rowsPerPage));
    dispatch(getAgencyProfile(parsedAgency?._id));
  }, [parsedAgency?._id, activePage, rowsPerPage]);

  useEffect(() => {
    setData(agencyWiseHost);
  }, [agencyWiseHost]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", Male);
    });
  });

  return (
    <>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3"></div>
            </div>
            <div class="card-body card-overflow pt-0">
              <table class="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Unique Id</th>
                    <th>Name</th>
                    <th>Gender</th>
                    <th>Coin</th>
                    <th>Country</th>
                    <th>Level</th>
                    <th>Follower</th>

                    <th>Following</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{(activePage - 1) * rowsPerPage + index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data.image ? data.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              className="mx-auto"
                            />
                          </td>
                          <td>{data.uniqueId ? data.uniqueId : "-"}</td>
                          <td>{data.name ? data.name : "-"}</td>
                          <td>{data.gender ? data.gender : "-"}</td>
                         
                          <td className="text-danger">
                            {data?.rCoin ? data?.rCoin : "0"}
                          </td>
                          <td className="text-success">
                            {data.country ? data.country : "-"}
                          </td>
                          <td className="text-warning">
                            {data.level.name ? data.level.name : "-"}
                          </td>
                          <td>{data.followers ? data.followers : "0"}</td>
                          <td>{data.following ? data.following : "0"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={totalAgencyWiseHost}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencyWiseUser;
