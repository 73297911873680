import React, { useEffect, useRef, useState } from "react";

//dayjs
import dayjs from "dayjs";

//jquery
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import {
  getAgencyWiseHost,
  handleBlockUnblockSwitch,
} from "../store/agency/action";

//routing
import { Link, useHistory } from "react-router-dom";

//MUI
import { Tooltip } from "@material-ui/core";

// import arraySort from "array-sort";

//image
import Male from "../assets/images/male.png";

//pagination
import Pagination from "../pages/Pagination";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { io } from "socket.io-client";
import { baseURL } from "../util/Config";
import { UNSET_ADMIN } from "../store/admin/types";

const AgencyWiseHost = () => {
  const { agencyWiseHost, totalAgencyWiseHost } = useSelector(
    (state) => state.agency
  );
  const history = useHistory();

  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [search, setSearch] = useState("ALL");

  const location = useLocation();
  const socketRef = useRef(null);
  const { admin } = useSelector((state) => state.admin);


  const ipAddress = localStorage.getItem("ipAddress");


  useEffect(() => {
    if (ipAddress.length > 0) {
      const socket = io.connect(baseURL, {
        transports: ["websocket", "polling", "flashsocket"],
        query: {
          adminRoom: admin && admin?._id,
        },
      });
      socketRef.current = socket ? socket : null;
      socketRef.current.on("connect", () => {
        console.log("Socket connected");
        // socketRef.current.emit("ticket", ipAddress && ipAddress);
      });
      socketRef.current.on("checkIpAdress", (data) => {
        console.log("data",data);
        if (data !== ipAddress) {
          dispatch({ type: UNSET_ADMIN });
        }
      });
      socketRef.current.on("disconnect", () => {
        console.log("Socket disconnected");
      });
      socketRef.current.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
      });
      return () => {
        console.log("Cleaning up socket connection");
        socketRef.current.disconnect();
      };
    }
  }, [ipAddress]);

  useEffect(() => {
    $("#card").click(() => {
      $("#datePicker");
    });
  }, []);

  useEffect(() => {
    dispatch(getAgencyWiseHost(location?.state?._id, activePage, rowsPerPage));
  }, [dispatch, location?.state?._id, activePage, rowsPerPage]);

  useEffect(() => {
    setData(agencyWiseHost);
  }, [agencyWiseHost]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const handleUserInfo = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    history.push("/admin/user/detail");
  };
  const handleUserHistory = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    history.push("/admin/user/history");
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", Male);
    });
  });

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3
              className="mb-3 text-light"
              style={{ color: "#e4eeff", textTransform: "capitalize" }}
            >
              Agency Wise Users
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  Agency
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  Agency Wise Users
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <h6
                    style={{
                      color: "#7d7f8c",
                      textTransform: "capitalize",
                      fontSize: "20px",
                    }}
                  >
                    {`${location?.state?.name}'s Users`}{" "}
                  </h6>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  {/* <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          if (e.target.value.length === 0) {
                            setSearch(e.target.value);
                            setActivePage(1);
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            setSearch(e.target.value);
                            setActivePage(1);
                          }
                        }}
                      />
                      <div
                        className="input-group-prepend border-0"
                        for="searchBar"
                        onClick={() => {
                          // Use setSearch with the value of the input field
                          setSearch(document.getElementById("searchBar").value);
                          setActivePage(1);
                        }}
                      >
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              <table class="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Unique Id</th>
                    <th>Name</th>
                    <th>Gender</th>
                    {/* <th onClick={handleCoinSort} style={{ cursor: "pointer" }}>
                      RCoin {coinSort ? " ▼" : " ▲"}
                    </th> */}
                    <th>Diamond</th>
                    <th>Coin</th>
                    <th>Country</th>
                    <th>Level</th>
                    <th>Follower</th>

                    <th>Following</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{(activePage - 1) * rowsPerPage + index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.image ? data?.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              className="mx-auto"
                            />
                          </td>
                          <td>{data?.uniqueId ? data?.uniqueId : "-"}</td>
                          <td>{data?.name ? data?.name : "-"}</td>
                          <td>{data?.gender ? data?.gender : "-"}</td>
                          <td className="text-info">
                            {data?.diamond ? data?.diamond : "-"}
                          </td>
                          <td className="text-danger">
                            {data?.rCoin ? data?.rCoin : "0"}
                          </td>
                          <td className="text-success">
                            {data?.country ? data?.country : "-"}
                          </td>
                          <td className="text-warning">
                            {data?.level?.name ? data?.level?.name : "-"}
                          </td>
                          <td>{data?.followers ? data?.followers : "0"}</td>
                          <td>{data?.following ? data?.following : "0"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="16" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={totalAgencyWiseHost}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencyWiseHost;
